import React from "react";
import { TabTitle } from "../../utiles/FunctionTitle";
import "./Aktuelles.css";

const Aktuelles = () => {
  TabTitle("FRISÖR SANDY | AKTUELLES");
  return (
    <main>
      <div>
        <div className="Aktuelles_container-top">
          <div className="Aktuelles_container-top-padding">
            <center className="Aktuelles_center">
              <h1>Education</h1>
            </center>
            <center className="Aktuelles_center">
              <p>
                Wir lieben unseren Beruf und sind immer auf der Suche nach neuen
                Herausforderungen. So nehmen wir regelmäßig an Schulungen teil,
                lernen neuste Trends und Techniken kennen und erweitern unser
                Wissen und unsere Fähigkeiten. Seit der Wiedereröffnung nach der
                Zwangsschließung durch Corona starten wir wieder durch.
              </p>
              Im Juni hatten wir ein Seminar bei Niko Kechlibaris, Art Director
              und Educator. Wir frischten unsere Kenntnisse im Bereich der
              Männerhaarschnitte - speziell der Fade-Cuts - auf und lernten neue
              Strähnentechniken kennen. Über Rooting, Face Framing, Refining war
              alles dabei, was sich Frauen im ColorUp Service nur wünschen
              können.
              <br />
              Mitte September erhielten wir eine COLOR.ME Schuung von Christina
              über alle KEVIN.MURPHY Regime und der professionellen Farblinie.
              <br />
              Eine Woche später folgte dann abermals ein Seminar bei Niko
              Kechlibaris. Der Fokus lag diesmal auf Damenhaarschnitte,
              insbesondere Trendsytles wie Curtain Bangs.
              <br />
              Anfang Oktober ging es dann nach Untergruppenbach auf ein
              Color.Me.Lab Seminar um die Color-Expertise auf das nächsthöhere
              Level zu bringen.
              <br />
              Dem folgte Mitte Oktober eine Blonde.Me Schulung in Karlsruhe -
              alles zum Thema Aufhellung. Die fortgeschrittenen Class
              vermittelte fundiertes Farbwissen vom Hintergrundpigment bis zur
              Applikation
              <br />
              Ende Oktober ging es dann erneut nach Untergruppenbach zum
              Refine.Me Seminar. Hier wurden uns tiefe Kenntnisse über die
              Tonisierung und Veredelung in allen Farbtiefen vermittelt -
              perfekt für High- Low- sowie Babylights aber auch Balayage, Ombre
              oder Sombre.
              <br />
              Im November folgte ein Einzeltraining für Schnitt, Farbe und
              Styling bei Andreas Schumacher, Bekannt als "Coach der Friseure"
              und Leiter der Akademie People & Schumacher.
              <br />
              Kurz darauf genossen wir eine Salonschulung bei Sara. Neue
              Kevin.Murphy Produkte und das Spa.Regime wurden vorgestellt. So
              gab es neben aktuellen Stylingtrends auch eine Einführung in die
              Kevin Murphy Experience, kurz KMX Behanldung, die wir ab sofort im
              Salon abieten. Dabei erhalten Kundinnen und Kunden ein absolutes
              Verwöhnprogramm inklusive Tiefenreinigung und Tiefenpflege, das
              die Haarqualität und auch Haarfarbe sichtlich verbessert.
              <br />
              Im Feburar steht erneut eine Seminar mit Sara an - wir können es
              kaum erwarten!
              <br />
              Wir freuen uns sehr darauf, all dieses neue Wissen im Alltag
              umzusetzen!
              <p />
            </center>
            <p>
              Sie können uns gerne per Email kontaktieren, um Termine zu
              vereinbaren.
            </p>
            <p>
              Terminvereinbarung:
              <br />
              <a
                href="mailto:info@friseur-am-mienchhof.de"
                className="Aktuelles_link"
              >
                info@friseur-am-moenchhof.de
              </a>
              <br />
            </p>
            <p>
              <b>Wir freuen uns auf Sie, bleiben Sie gesund!</b>
            </p>
            <p>
              <b>Ihr Frisör Sandy am Mönchhof</b>
            </p>
            <br />
          </div>

          {/*
            <center>
              <p>Aktuell sind wir im Urlaub. </p>
              <p>
                {" "}
                Ab Dienstag, dem 22.08 sind wir wieder zu den gewohnten
                Öffnungszeiten für Sie da.{" "}
              </p>
              <p>
                Bis dahin wünschen wir Ihnen eine schöne Zeit und freuen uns
                darauf Sie in aller Frische wieder bei uns begrüßen zu dürfen!
                <br />
                <br />
                <b>Ihr Frisör Sandy am Möchhof Team</b>
              </p>
            </center>
          </div>
  </div>*/}

          {/* <div className="Aktuelles_container-top">
          <div className="Aktuelles_container-top-padding">
            <center className="Aktuelles_center">
              <h1>Corona-Information</h1>
            </center>
            <p>
              Ab Mittwoch, 24.11.2021 gilt die <b>3G - Regel</b> (mind. 14 Tage
              nach der vollständigen Impfung) oder <b>genesen</b> (Nachweis
              einer genesenen Infektion, die max. 3 Monate zurckliegt) oder{" "}
              <b>PCR-getestet</b> (max. 24h alt) sein.
            </p>
            <p>Zudem gilt die FFP-2 Maskenpflicht.</p>
            <p>
              Für Körpernahe Dienstleistungen gilt sowohl in der Basis- als auch
              der Warnstufe die 3G Regel. Dies bedeutet entweder geimpft,
              genesen oder getestet (Antigen-Schnelltest aus einem
              Corona-Schnelltestcenter, keine Selbsttests).
              <br />
              In der Alarmstufe 1 und Alarmstufe 2 sind Friseure von der 2G
              Regel ausgenommen. Hier gilt die 3G-Regel mit PCR-Test. Das heißt,
              Sie müssen geimpft, genesen oder PCR-getestet sein.
            </p>
            <p>
              Aktuelle Änderungen der Corona-Verordnung können Sie{" "}
              <a
                className="Aktuelles_link"
                href="https://www.baden-wuerttemberg.de/de/service/aktuelle-infos-zu-corona/aktuelle-corona-verordnung-des-landes-baden-wuerttemberg/"
              >
                hier
              </a>{" "}
              nachlesen. Außerdem erhalten Sie{" "}
              <a
                className="Aktuelles_link"
                href="https://www.stuttgart.de/leben/gesundheit/corona/fallzahlen-und-impfungen.php"
              >
                hier
              </a>{" "}
              einen Überblick über aktuelle Corona-Kennzahlen für Stuttgart und
              die aktuelle Stufe. Eine Übersicht über Teststellen der Region
              Stuttgart finden Sie{" "}
              <a
                className="Aktuelles_link"
                href="https://www.stuttgart.de/leben/gesundheit/corona/schnelltestzentren.php"
              >
                hier
              </a>
              .
            </p>
          </div>
              </div> */}
          {/*
        <div className="Aktuelles_container">
          {/* Linke Seite */}
          {/*<div className="Aktuelles_left">
            <center className="Aktuelles_center">
              <h1>Education</h1>
            </center>
            <center className="Aktuelles_center">
              <p>
                Wir lieben unseren Beruf und sind immer auf der Suche nach neuen
                Herausforderungen. So nehmen wir regelmäßig an Schulungen teil,
                lernen neuste Trends und Techniken kennen und erweitern unser
                Wissen und unsere Fähigkeiten. Seit der Wiedereröffnung nach der
                Zwangsschließung durch Corona starten wir wieder durch.
              </p>
              Im Juni hatten wir ein Seminar bei Niko Kechlibaris, Art Director
              und Educator. Wir frischten unsere Kenntnisse im Bereich der
              Männerhaarschnitte - speziell der Fade-Cuts - auf und lernten neue
              Strähnentechniken kennen. Über Rooting, Face Framing, Refining war
              alles dabei, was sich Frauen im ColorUp Service nur wünschen
              können.
              <br />
              Mitte September erhielten wir eine COLOR.ME Schuung von Christina
              über alle KEVIN.MURPHY Regime und der professionellen Farblinie.
              <br />
              Eine Woche später folgte dann abermals ein Seminar bei Niko
              Kechlibaris. Der Fokus lag diesmal auf Damenhaarschnitte,
              insbesondere Trendsytles wie Curtain Bangs.
              <br />
              Anfang Oktober ging es dann nach Untergruppenbach auf ein
              Color.Me.Lab Seminar um die Color-Expertise auf das nächsthöhere
              Level zu bringen.
              <br />
              Dem folgte Mitte Oktober eine Blonde.Me Schulung in Karlsruhe -
              alles zum Thema Aufhellung. Die fortgeschrittenen Class
              vermittelte fundiertes Farbwissen vom Hintergrundpigment bis zur
              Applikation
              <br />
              Ende Oktober ging es dann erneut nach Untergruppenbach zum
              Refine.Me Seminar. Hier wurden uns tiefe Kenntnisse über die
              Tonisierung und Veredelung in allen Farbtiefen vermittelt -
              perfekt für High- Low- sowie Babylights aber auch Balayage, Ombre
              oder Sombre.
              <br />
              Im November folgte ein Einzeltraining für Schnitt, Farbe und
              Styling bei Andreas Schumacher, Bekannt als "Coach der Friseure"
              und Leiter der Akademie People & Schumacher.
              <br />
              Kurz darauf genossen wir eine Salonschulung bei Sara. Neue
              Kevin.Murphy Produkte und das Spa.Regime wurden vorgestellt. So
              gab es neben aktuellen Stylingtrends auch eine Einführung in die
              Kevin Murphy Experience, kurz KMX Behanldung, die wir ab sofort im
              Salon abieten. Dabei erhalten Kundinnen und Kunden ein absolutes
              Verwöhnprogramm inklusive Tiefenreinigung und Tiefenpflege, das
              die Haarqualität und auch Haarfarbe sichtlich verbessert.
              <br />
              Im Feburar steht erneut eine Seminar mit Sara an - wir können es
              kaum erwarten!
              <br />
              Wir freuen uns sehr darauf, all dieses neue Wissen im Alltag
              umzusetzen!
              <p />
            </center>
            <p>
              Sie können uns gerne per Email kontaktieren, um Termine zu
              vereinbaren.
            </p>
            <p>
              Terminvereinbarung:
              <br />
              <a
                href="mailto:info@friseur-am-mienchhof.de"
                className="Aktuelles_link"
              >
                info@friseur-am-moenchhof.de
              </a>
              <br />
            </p>
            <p>
              <b>Wir freuen uns auf Sie, bleiben Sie gesund!</b>
            </p>
            <p>
              <b>Ihr Frisör Sandy am Mönchhof</b>
            </p>
            <br />
          </div> */}

          {/* Rechte Seite */}
          {/* <div className="Aktuelles_right">
            <h1>Infektionsschutzmaßnahmen</h1>
            <br />
            <p />
            <p>
              <b>Es gelten folgende Auflagen:</b>
            </p>
            <p />
            <p>• 3G-Regel: Geimpft, Genesen oder PCR-Getestet</p>
            <p>• Ausnahmen von der strengeren Testpflicht (3G-PLUS-Regel):</p>
            <p>
              für einige Personengruppen reicht ein negativen
              Antigen-Schnelltest: Personen bis einschließlich 17 Jahre, die
              nicht mehr zur Schule gehen; Personen, die sich aus medizinischen
              Gründen nicht impfen lassen können (ärztlicher Nachweis
              erforderlich); Personen, für die es keine allgemeine
              Impfempfehlung der Ständigen Impfkommission gibt; Schwangere und
              Stillende, da es für diese Gruppen erst seit dem 10. September
              2021 eine Impfempfehlung gibt
            </p>
            <p>
              Für Kinder, die noch eingeschult sind und Schüler, die an
              regelmäßigen Tests im Rahmen des Schulbesuchs teilnehmen, ist ein
              Schulnachweis erforderlich
            </p>
            <p>
              • Tragen eines <b>medizinischen Mund-Nasen-Schutzes: FFP2</b>
            </p>
            <p>• Einhaltung des Schutzabstandes</p>
            <p>• Desinfizieren der Hände</p>
            <p>
              • Kundenkontaktdaten sowie Zeitpunkt des Betretens und Verlassens
              des Salons müssen dokumentiert werden, bei Weigerung dürfen wir
              Sie nicht bedienen
            </p>
            <p>
              • Kundinnen und Kunden mit Symptomen einer Atemwegsinfektion
              dürfen nicht bedient werden
            </p>
            <p>
              • Keine Trockenhaarschnitte, was bedeutet, dass die Haare im Salon
              gewaschen werden müssen (auch bei Kleinkindern und Ponyschneiden)
            </p>
            <p>
              • Kundinnen und Kunden dürfen nur nach vorheriger telefonischer
              Terminvereinbarung einzeln eintreten
            </p>
            <p>• Kundinnen und Kunden dürfen Ihre Haare nicht selbst föhnen</p>

            <p>
              <b>Zudem sind folgende Dienstleistungen untersagt: </b>
            </p>
            <p>• Bewirtung</p>
            <p>• Bartpflege / Rasur</p>
            <p>
              • Trockenhaarschnitte / Ponyschneiden bei nicht im Salon
              gewaschenem Haar
            </p>
            <p />
            <br />
              </div> */}
        </div>
      </div>
    </main>
  );
};

export default Aktuelles;
