import React from "react";
import { TabTitle } from "../../utiles/FunctionTitle";
import "./Kontakt.css";

const Kontakt = () => {
  TabTitle("FRISÖR SANDY | KONTAKT");
  return (
    <main>
      <div>
        <div className="Kontakt_container">
          <div className="Kontakt_inner">
            <h1>Informationen</h1>
            <p>
              {" "}
              <b> Frisör Sandy am Mönchhof</b>
              <br /> A. Koschmieder-Menendez
              <br /> Goezstraße 3
              <br /> 70899 Stuttgart
              <br />
              <br /> Tel. 0711 - 83 88 56 96
              <br />
              <a
                className="Kontakt_link"
                href="mailto:info@friseur-am-moenchhof.de"
              >
                info@friseur-am-moenchhof.de
              </a>
              <br />
            </p>
            <p>
              Schreiben Sie uns bei Fragen oder sonstigen Anliegen gerne eine
              Email.
              <br />
              Bei Terminanfragen empfiehlt es sich, mehrere Wahlmöglichkeiten
              (Tag und Uhrzeiten) anzugeben.
            </p>
            <p />
            <b>
              <p>Wir freuen uns auf Sie!</p>
              <p>Ihr Frisör Sandy am Mönchhof</p>
            </b>
            <p>Sandy, Simone, Maike, Madeleine & Emily</p>

            <font className="font">
              <p />
              <h1>Kundeninformation</h1>
              <p />
              <p>
                WIR Innungsfriseurinnen und -friseure sagen unseren Kundinnen
                und Kunden <b>DANKE</b>
              </p>
              <p>... das Sie uns die Treue halten und</p>
              <p>
                ... dass Sie während des Lockdowns{" "}
                <b>keine illegalen Friseurtermine wahrgenommen haben!</b>{" "}
              </p>
              <p>
                Wer während des Lockdowns mit frisch gestylten Haaren zu sehen
                war, hat sehr wahrscheinlich gegen die Corona-Auflagen
                verstoßen.
              </p>
              <p>
                <b>GEMEINSAM GEGEN DIE PANDEMIE!</b>
              </p>
              <p>Bis hoffentlich bald sagen</p>
              <p>Ihre Innungsfriseurinnen und -friseure</p>
            </font>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Kontakt;
