import { v4 as uuidv4 } from "uuid";

const dataSlider = [
  {
    id: uuidv4(),
    title: "Browlamination • Augenbrauenfarbe",
    subTitle: "Lorem",
  },
  {
    id: uuidv4(),
    title: "Ihr Style ist unsere Passion.",
    subTitle: "Lorem",
  },
  {
    id: uuidv4(),
    title: "Stilberatung • Damenfrisuren",
    subTitle: "Lorem",
  },
  {
    id: uuidv4(),
    title: "Intensivpflege • Herrenfrisuren",
    subTitle: "Lorem",
  },
  {
    id: uuidv4(),
    title: "Kinderhaarschnitte • Dauerwelle",
    subTitle: "Lorem",
  },
  {
    id: uuidv4(),
    title: "Colorationen • Foliensträhnen",
    subTitle: "Lorem",
  },
  {
    id: uuidv4(),
    title: "Augenbrauenformen • Wimpernfarbe",
    subTitle: "Lorem",
  },
  {
    id: uuidv4(),
    title: "Styling Produkte • Pflege Produkte",
    subTitle: "Lorem",
  },
];

export default dataSlider;
