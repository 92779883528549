import "./App.css";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./Components/Header/Header";
import Home from "./Pages/Home/Home";
import Aktuelles from "./Pages/Aktuelles/Aktuelles";
import Footer from "./Components/Footer/Footer";
import KevinMurphy from "./Pages/KevinMurphy/KevinMurphy";
import Kontakt from "./Pages/Kontakt/Kontakt";
import Impressum from "./Pages/Imprint/Impressum";
import Datenschutz from "./Pages/Imprint/Datenschutz";

function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/Aktuelles" element={<Aktuelles />} />
          <Route path="/KevinMurphy" element={<KevinMurphy />} />
          <Route path="/Kontakt" element={<Kontakt />} />
          <Route path="/Impressum" element={<Impressum />} />
          <Route path="/Datenschutz" element={<Datenschutz />} />
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </>
  );
}

export default App;
