import React from "react";
import { TabTitle } from "../../utiles/FunctionTitle";
import "./KevinMurphy.css";
const KevinMurphy = () => {
  TabTitle("FRISÖR SANDY | KEVIN.MURPHY");
  return (
    <main>
      <div>
        <div className="KM_container">
          <div className="KM_top">
            <div>
              <h1>Über KEVIN.MURPHY</h1> <br />
              <b>
                In unserem Salon verwenden wir ausschließlich KEVIN.MURPHY
                Produkte - professionelle Haarpflege, die Leidenschaft für Haare
                wiederspiegelt.
              </b>
              <p>
                Sämtliche Produkte unterliegen strengen Qualitätsstandards,
                werden mit alternativen Testmethoden entwickelt und enthalten
                ausgewählte natürliche Inhaltsstoffe. So setzt KEVIN.MURPHY auf
                natürliche Inhaltsstoffe.
              </p>
              <p>
                Gegründet wurde die Haarkosmetikmarke von Kevin Murphy.
                <br />
                <br />
              </p>
            </div>
          </div>

          <div className="KM_bottom">
            <h1>KEVIN.MURPHY Produkte in unserem Sortiment</h1>
            <p>
              Für jedes Bedürfnis schafft Kevin Murphy dass passende Regime.
              <br />
              So gibt es tiefenreinigende Produkte, Produkte die perfekt auf die
              Kopfhautpflege abgestimmt wurden, ein Regime für Volumen, eins für
              Kräftigung der Haare, Produkte für Feuchtigkeit, für strapaziertes
              Haar, zur Farbauffrischung von Blondtönen, ein revitalisierendes
              Regime, Produkte, die die Haarstruktur beruhigen, Produkte
              speziell für welliges und gelocktes Haar, hitzeaktivierende Style
              Extender, für Fülle und Textur sowie Style und Definition. Viele
              Produkte sind zudem als kleine Reisegröße - MINI.MURPHY - zu
              erhalten
            </p>
            <p>
              Welche KEVIN.MURPHY Produkte sich genau in unserem Sortiment
              befinden, erfahren Sie über den folgenden Link:
            </p>
            <a
              className="KM_link"
              href="https://kevinmurphy.de/"
              target={"_blank"}
              rel="noreferrer"
            >
              KEVIN.MURPHY
            </a>
            <br />
            <div style={{ height: "0.313em", width: "100%" }} />
          </div>
        </div>
      </div>
    </main>
  );
};

export default KevinMurphy;
