import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="Footer_footer">
      <div className="Footer_div1">
        <div className="Footer_div2">
          <h2 className="Footer_h2_1">
            {"//\u00A0"}
            <a className="Footer_a" href="./Impressum">
              IMPRESSUM
            </a>
            <span className="Footer_span">|</span>
            <a className="Footer_a" href="./Datenschutz">
              DATENSCHUTZ
            </a>
          </h2>

          <h2 className="Footer_h2_2">©2021</h2>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
