import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./Header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  const [isMobile, setIsMobile] = useState(false);
  return (
    <header className="Header_header">
      <nav className="Header_nav">
        <ul className="Header_ul">
          <div className="Header_div">
            <li className={isMobile ? "mobile_logo" : "Header_li"}>
              <a
                href="/"
                style={{
                  fontSize: "1.5em",
                  fontWeight: "500",
                  border: "none",
                }}
              >
                <img
                  className="Header_img"
                  alt="Logo"
                  src="/img/logo-retina.png"
                />
              </a>
            </li>
            <span
              className={isMobile ? "nav-links-mobile" : "Header_span"}
              onClick={() => setIsMobile(false)}
            >
              <li className="Header_li">
                <NavLink
                  className="Header_navlink"
                  to="/"
                  activeClassName="active"
                >
                  HOME
                </NavLink>
              </li>
              <li className="Header_li">
                <NavLink
                  className="Header_navlink"
                  to="/Aktuelles"
                  activeClassName="active"
                >
                  AKTUELLES
                </NavLink>
              </li>
              <li className="Header_li">
                <NavLink
                  className="Header_navlink"
                  to="/KevinMurphy"
                  activeClassName="active"
                >
                  KEVIN.MURPHY
                </NavLink>
              </li>
              <li className="Header_li">
                <NavLink
                  className="Header_navlink"
                  to="/Kontakt"
                  activeClassName="active"
                >
                  KONTAKT
                </NavLink>
              </li>
            </span>
          </div>
        </ul>

        <button
          className="mobile-menu-icon"
          onClick={() => setIsMobile(!isMobile)}
        >
          {isMobile ? (
            <FontAwesomeIcon icon={faXmark} />
          ) : (
            <FontAwesomeIcon icon={faBars} />
          )}
        </button>
      </nav>
    </header>
  );
};

export default Header;
