import React from "react";
import Slider from "../../Slider/Slider";
import { TabTitle } from "../../utiles/FunctionTitle";
import "./Home.css";

const Home = () => {
  TabTitle("FRISÖR SANDY | HOME");
  return (
    <main>
      <Slider />
      <div>
        <div className="Home_container">
          {/* Left Side */}
          <div className="Home_left">
            <h1>Herzlich Willkommen</h1>

            <p>
              zu hoher Frisörhandwerkskunst, professioneller Beratung und
              individuellen Haarkreationen. In entspannter, nahezu familiärer
              Atmosphäre, bieten wir Ihnen eine umfangreiche Auswahl an
              Dienstleistungen rund ums Haar:
            </p>
            <br />
            <center>
              <p>
                <b>Haarschnitte</b>
              </p>
              <p>
                inklusive Beratung, Waschen, Föhnen und Styling für Mann und
                Frau
              </p>
              <br />

              <p>
                <b>Farbbehandlungen</b>
              </p>
              <p>inklusive Pfelge</p>
              <br />

              <p>
                <b>Intensivpflege</b>
              </p>
              <p>mit warmer Kompresse </p>
              <br />

              <p>
                <b>Styling</b>
              </p>
              <p>Waschen und Föhnen</p>
              <br />

              <p>
                <b>Beauty</b>
              </p>
              <p>
                Zupfen sowie Färben der Augenbrauen und Wimpern Brow Lamination
                inklusive Färben
              </p>
              <br />

              <p>
                <b>Scalp-Spa Treatment</b>
              </p>
              <p>Kopfhautbehandlung inklusive Massage </p>
              <br />

              <p>
                <b>Dauerwelle</b>
              </p>
              <p>inklusive Pflege</p>
            </center>
            <br />

            <p>
              Wir verwenden ausschließlich Produkte der Marke
              <b> KEVIN.MURPHY</b>. Die hochwertige, australische und
              friseurexklusive Haarkosmetikmarke ist bekannt für einzigartige
              Haarpflege- und Stylingprodukte, die von der Hautpflege inspiriert
              wurde. So ermöglich KEVIN.MURPHY maßgeschneiderte Lösungen für
              jedes Haarbedürfnis und jeden Haartyp.
            </p>
            <p>
              KEVIN.MURPHY steht für hohe Produktqualität, die neuste
              Technologien mit wertvollen Wirkstoffen aus der Natur in sich
              vereint. So werden aus der Natur gewonnene Inhaltsstoffe aus
              nachhaltigen und erneuerbaren Quellen und verantwortungsvolle
              Verpackungen verwendet.
            </p>
          </div>
          {/* Right Side */}
          <div className="Home_right">
            <h1>Öffnungszeiten</h1>
            <p />
            <p> Dienstag: 9-22 Uhr</p>
            <p> Donnerstag: 9-18 Uhr</p>
            <p> Freitag: 9-18 Uhr</p>
            <p> Samstag: 8-13 Uhr</p>

            <p>
              {" "}
              <b>Wir freuen uns auf Ihren Besuch,</b>
            </p>

            <p>
              {" "}
              <b>Ihr KEVIN.MURPHY Friseur Sandy am Mönchhof</b>
            </p>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Home;
